import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';

import { DateRange } from 'components/DateRange';
import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { getIdByNetParkCode } from 'entities/Facility/util';
import { renderEnhancedField } from 'util/form/renderers';
import { isTouchDevice } from 'util/form/utility';
import './styles.scss';

/* A form that can be used in the booking box to kick off a new reservation. */
export class BookBoxForm extends BaseForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // minimum date/time is the quarter hour immediately preceding 75 minutes from now
    var min = moment().add(75, 'minutes');
    if (min.minute() >= 1 && min.minute() <= 14) {
      min.minute(0);
    } else if (min.minute() >= 15 && min.minute() <= 29) {
      min.minute(15);
    } else if (min.minute() >= 30 && min.minute() <= 44) {
      min.minute(30);
    } else {
      min.minute(45);
    }

    // for date management
    this.state = {
      ...this.state,
      startMoment: undefined,
      endMoment: undefined,
      minMoment: min,
    };

    // to track when the user has chosen dates
    this.state = {
      ...this.state,
      datesApplied: false,
    };

    // to track when calendar is shown
    this.state = {
      ...this.state,
      calendarShown: false,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // attach handlers for the date picker
    $('input[name="datetimes"]').on('show.daterangepicker', () => {
      // disable the "next" button while the calendar is shown
      this.setState({ calendarShown: true });
    });
    $('input[name="datetimes"]').on('hide.daterangepicker', () => {
      // enable the "next" button when the calendar is hidden
      this.setState({ calendarShown: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if both location and dates are set, submit the form
    if (this.props.facilityId && this.props.start && this.props.end) {
      this.props.submit();
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // date format
    var format = 'ddd, LL';
    if ($(window).width() <= 576) {
      format = 'ddd, M/D';
    } else if ($(window).width() <= 768) {
      format = 'ddd, l';
    }

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        <div className="container-fluid">
          <div className="row pt-2">
            <div
              className="col-4"
              onClick={() => {
                this.setState({
                  showDropOff: false,
                  showPickUp: false,
                });
              }}
            >
              <Field
                type="select"
                label={this.props.minimal ? undefined : 'Location'}
                name="facilityId"
                labelClassName="col-form-label col-form-label-lg"
                className={'form-control form-control' + (isTouchDevice() ? '-sm' : '-lg')}
                component={renderEnhancedField}
                placeholder="Location"
                tooltip={!this.props.minimal ? `The location at which you will be parking` : ''}
                required={true}
                disabled={this.props.submitting}
                normalize={(value) => Number(value)}
              >
                {this.props.facilities &&
                  this.props.facilities.length > 0 &&
                  this.renderFacilityOptions(
                    this.props.facilities,
                    true,
                    this.props.minimal ? 'Lot' : 'Select a location',
                  )}
              </Field>
            </div>
            <div className="col-8">
              {!this.props.minimal && (
                <label className="col-form-label col-form-label-lg">Dates</label>
              )}
              <DateRange
                className={'form-control form-control' + (isTouchDevice() ? '-sm' : '-lg')}
                format={format}
                form={this.props.form}
                minMoment={this.state.minMoment}
                onApply={(start, end) => {
                  // rather arbitrarily, set the start time to 6 AM and the end time to 5 PM;
                  // these are just defaults, and the user will be able to change them
                  start.hour(6);
                  end.hour(17);
                  start.minute(0);
                  end.minute(0);
                  start.second(0);
                  end.second(0);
                  start.millisecond(0);
                  end.millisecond(0);

                  // update the state
                  this.setState({ datesApplied: true, startMoment: start, endMoment: end });

                  // reset any FPP points
                  this.props.dispatch(change(this.props.form, 'fppRedeemed', 0));

                  // update the form
                  this.props.dispatch(change(this.props.form, 'start', start.unix()));
                  this.props.dispatch(change(this.props.form, 'end', end.unix()));
                }}
                placeholder={this.props.minimal ? 'Dates' : undefined}
                tooltip={this.props.minimal ? '' : undefined}
              />
              {this.props.dateTimeError && (
                <span className="png-field-error">{this.props.dateTimeError}</span>
              )}
            </div>
          </div>
          {this.props.showDisclaimer && (
            <div className="row">
              <div className="col text-center png-reservation-book-box-disclaimer">
                <FontAwesomeIcon icon="info-circle" /> Taxes & Fees includes applicable taxes,
                surcharges and online reservation fee.
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
BookBoxForm = reduxForm({
  // validate the date
  validate: (values) => {
    const errors = {};

    // make sure the end date is equal to or after the start date
    if (values.start && values.end && moment.unix(values.end).isBefore(moment.unix(values.start))) {
      errors.end = 'Check out cannot be before check in';
    }

    return errors;
  },
})(BookBoxForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // chosen facility
  facilityId: formValueSelector(ownProps.form)(state, 'facilityId'),

  // start date
  start: formValueSelector(ownProps.form)(state, 'start'),

  // end date
  end: formValueSelector(ownProps.form)(state, 'end'),

  // set initial values, but be careful not to smash any currently set values
  initialValues:
    ownProps.customer && ownProps.facilities
      ? {
          facilityId: getIdByNetParkCode(ownProps.facilities, ownProps.customer.locationCode),
        }
      : null,

  // date/time errors
  dateTimeError:
    state.form.bookBoxForm && state.form.bookBoxForm.syncErrors
      ? state.form.bookBoxForm.syncErrors.start || state.form.bookBoxForm.syncErrors.end
      : null,
});

// turn this into a container component
BookBoxForm = withRouter(connect(mapStateToProps)(BookBoxForm));

// set default props
BookBoxForm.defaultProps = {
  form: 'bookBoxForm',
  minimal: false,
  showDisclaimer: true,
};

export default BookBoxForm;
