import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';

import { BasePureComponent } from 'components/Base';
import BookBoxForm from './components/BookBoxForm';
import './styles.scss';

/* A booking box that can be used to kick off a new reservation. Can optionally be on top of a background image. */
export class BookBox extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for sticky behavior
    this.state = {
      ...this.state,
      sticky: false,
      stickyElementOffset: 0,
    };

    // capture refs to help us with the sticky behavior
    this.stickyRef = React.createRef();
    this.placeholderRef = React.createRef();

    // bind the handleScroll() method
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // add the scroll listener
    window.addEventListener('scroll', this.handleScroll);

    // fire it once to set the initial state
    setTimeout(() => {
      this.handleScroll();
    }, 0);
  }

  componentWillUnmount() {
    // parent, for lifecycle logging
    super.componentWillUnmount();

    // remove the scroll listener
    window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Handle the scroll event to make the box sticky. If the box is at the top of
   * the screen, we stick it.
   */
  handleScroll = () => {
    // get the sticky element's bounding rectangle
    const stickyRect = this.stickyRef.current.getBoundingClientRect();

    // not sure why, but this sometimes fires with values of 0, 0, 0, 0
    if (
      stickyRect.top === 0 &&
      stickyRect.right === 0 &&
      stickyRect.bottom === 0 &&
      stickyRect.left === 0
    ) {
      // ignore
      return;
    }

    // get the offset of the top of the form row from the viewport
    const stickyOffset = stickyRect.top;

    // we also need the offset of the placeholder
    const placeholderRect = this.placeholderRef.current.getBoundingClientRect();
    const placeholderOffset = placeholderRect.top;

    // get the sticky element
    const stickyElement = document.getElementById('sticky');

    // get the placeholder element
    const placeholder = document.getElementById('placeholder');

    // if the sticky offset is negative, we are newly sticky
    if (stickyOffset < 0) {
      // set the state
      this.setState({ isStuck: true, stickyOffset, placeholderOffset });

      // style the elements
      stickyElement.classList.add('sticky');
      placeholder.style.display = 'block';
      placeholder.style.height = `${stickyRect.height}px`;
    } else {
      // if the placeholder offset is negative, we need to remain sticky
      if (placeholderOffset < 0) {
        return;
      } else {
        // set the state
        this.setState({ isStuck: false, stickyOffset, placeholderOffset });

        // style the elements
        stickyElement.classList.remove('sticky');
        placeholder.style.display = 'none';
      }
    }
  };

  render() {
    // parent, for lifecycle logging
    super.render();

    // the book box form
    const form = (minimal, showDisclaimer) => (
      <BookBoxForm
        customer={this.props.customer}
        facilities={this.props.facilities}
        minimal={minimal}
        showDisclaimer={showDisclaimer}
        onSubmit={(values) => {
          // start the booking by jumping into the reservation flow
          this.props.history.push(
            `/reservations/new/${values.facilityId}/${values.start}/${values.end}`,
          );

          // reset the form for next time
          this.props.resetForm();
        }}
      />
    );

    // render
    if (this.props.facilities && this.props.facilities.length > 0) {
      return (
        <div className="container-fluid">
          <div id="sticky" ref={this.stickyRef} className="card">
            <div className="card-header png-background">
              <h5 className="mb-0">Book a Reservation</h5>
            </div>
            <div className="card-body png-bookbox-body pt-2 pl-0 pr-0">
              {form(true, !this.state.isStuck)}
            </div>
          </div>
          <div id="placeholder" ref={this.placeholderRef}>
            {/* this is to prevent content from jumping while sticky */}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // the existing customer, if there is one
    customer: state.context.customer,

    // facilities
    facilities: state.context.facilities,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // reset the form
  resetForm: () => {
    dispatch(reset('bookBoxForm'));
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookBox));
