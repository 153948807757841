import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* An image carousel. */
export class Carousel extends BasePureComponent {
  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // initialize the carousel; delay to make sure it's loaded
    setTimeout(() => {
      const carouselElement = document.querySelector('#indicators');
      if (carouselElement) {
        $('#indicators').carousel({
          interval: 5000, // cycle speed in milliseconds
        });
      }
    }, 500);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div id="indicators" className="carousel slide" data-ride="carousel">
              {/* indicators at the bottom of the image */}
              <ol className="carousel-indicators">
                {this.props.images.map((_, index) => {
                  return (
                    <li
                      key={index}
                      data-target="#indicators"
                      data-slide-to={index}
                      className={index === 0 ? 'active' : ''}
                    ></li>
                  );
                })}
              </ol>

              {/* the images */}
              <div className="carousel-inner">
                {this.props.images.map((image, index) => {
                  /* item container */
                  return (
                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                      {/* image */}
                      <img src={image.source} className="d-block w-100" alt={image.description} />

                      {/* caption (large screens) */}
                      <div className="carousel-caption">
                        <h5>{image.title}</h5>
                        <p>{image.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* previous control */}
              <a
                className="carousel-control-prev"
                href="#indicators"
                role="button"
                data-slide="prev"
              >
                <FontAwesomeIcon icon="chevron-left" />
                <span className="sr-only">Previous</span>
              </a>

              {/* next control */}
              <a
                className="carousel-control-next"
                href="#indicators"
                role="button"
                data-slide="next"
              >
                <FontAwesomeIcon icon="chevron-right" />
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// set prop types and required-ness
Carousel.propTypes = {
  images: PropTypes.array.isRequired,
};

// set defaults
Carousel.defaultProps = {
  // 12 steps
  images: [
    {
      source: 'https://assets.bookparkngo.com/process/1.png',
      title: 'Arrival',
      description: 'A helpful employee will greet you upon arrival',
    },
    {
      source: 'https://assets.bookparkngo.com/process/2.png',
      title: 'Find a Space',
      description: 'You will be directed to an available parking space',
    },
    {
      source: 'https://assets.bookparkngo.com/process/3.png',
      title: 'Check In',
      description:
        'A shuttle driver will meet you at your spot, giving you a ticket that indicates the spot number',
    },
    {
      source: 'https://assets.bookparkngo.com/process/4.png',
      title: 'Walking to Shuttle',
      description: 'The shuttle driver will carry your luggage to the shuttle',
    },
    {
      source: 'https://assets.bookparkngo.com/process/5.png',
      title: 'Shuttle Boarding',
      description: `Find a seat on the shuttle, and enjoy the ride; we'll drop you at your departure terminal`,
    },
    {
      source: 'https://assets.bookparkngo.com/process/6.png',
      title: 'Return',
      description:
        'When you return from your trip, go to the shuttle loading area; the shuttle driver will take your ticket',
    },
    {
      source: 'https://assets.bookparkngo.com/process/7.png',
      title: 'Back at the Lot',
      description: 'The shuttle driver will take you right to your car',
    },
    {
      source: 'https://assets.bookparkngo.com/process/9.png',
      title: 'Checking Out',
      description: `Let us know your reservation number at check out, and we'll take care of the rest; if you did not prepay, you'll pay now`,
    },
    {
      source: 'https://assets.bookparkngo.com/process/11.png',
      title: 'Our Gift to You!',
      description: `We'll give you a complimentary bottle of water for the road, subject to availability`,
    },
    {
      source: 'https://assets.bookparkngo.com/process/12.png',
      title: `Park 'N Go`,
      description: 'Let us take the hassle out of airport parking!',
    },
  ],
};

// turn this into a container component
export default withRouter(connect()(Carousel));
